@import "colors";
@import "shadows";

:root {
    @each $name, $color in $palette {
        #{$name}: $color
    }
    @each $name, $shadow in $shadows {
        #{$name}: $shadow
    }
}

.theme-light {
    @each $name, $color in $colors {
        #{$name}: nth($color, 1);
    }
}

.theme-dark {
    @each $name, $color in $colors {
        #{$name}: nth($color, 2);
    }
}

.theme-visible-drawing {
#{--card-background}: rgb(223, 223, 233)
}