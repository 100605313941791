@import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./../node_modules/ngx-ui-switch/ui-switch.component.css";
@import "styles/animations";
@import "styles/theme";
@import "styles/common";
#viewerContainer {
    height: 100%;
    min-height: 100px;
    max-width: 100%;
}

:root {
    --white-primary: #ffffff;
    --white-secondary: #eaeaea;
    --white-tertiary: #c6c6c6;
    --black-primary: #1d1d1d;
    --black-secondary: #352f2f;
    --black-tertiary: #4f4f4f;
    --green-primary: #2aac89;
    --green-secondary: #45D7B0;
    --green-tertiary: #6DF4D0;
    --blue-primary: #2E53D2;
    --blue-secondary: #5372E1;
    --blue-tertiary: #8199F0;
    --red-primary: #D05D5D;
    --red-secondary: #E47272;
    --red-tertiary: #F08181;
    --yellow-primary: #E9E588;
    --yellow-secondary: #F3F0A7;
    --yellow-tertiary: #FFFDD4;
    --purple-primary: #D292C8;
    --purple-secondary: #F6CAEF;
    --ornamental-pastelgreen: #9DF1DB;
    --ornamental-pastelblue: #CFFCF9;
    --ornamental-pastelred: #F9D3D3;
    --ornamental-pastelyellow: #F6F5CC;
    --shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -1px rgba(0, 0, 0, 0.15);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    --card-shadow: 0 0.250em 0.375em rgba(50, 50, 93, .09), 0 0.063em 0.188em rgba(0, 0, 0, .08);
    --border-grey: 1px solid var(--white-secondary);
    --focus-ring-color: rgba(66, 153, 225, 0.6);
}

html,
body {
    height: 100%;
}

.makeRoomForToolbar {
    margin-top: 56px;
}

a {
    font-family: Poppins;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

a:hover {
    text-decoration: none;
}

button {
    white-space: nowrap;
    text-align: center;
}

.trans-link {
    transition: all .25s ease;
    color: var(--black-primary);
    padding: .65rem .75rem;
    font-size: 1.1rem;
}

.trans-link:hover {
    background-color: var(--white-secondary);
    border-radius: 4px;
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
}

h1 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
}

h2 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
}

h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
}

h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}

h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
}

b {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.error {
    color: var(--red-primary);
}

.disclaimer {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

input {
    border: 1px solid var(--white-secondary);
    border-radius: 4px;
    background: var(--surface-background);
    color: var(--text-color);
}

ion-icon {
    font-size: 30px;
}

.scrawlbrawl-logo {
    fill: white;
}

.keyText {
    font-size: 14px;
    font-style: italic;
    color: var(--green-primary);
}

.theirPrompt {
    border: 1px solid rgb(224, 224, 224);
    padding-left: 5px;
}

.yourPrompt {
    border: 1px solid var(--green-secondary);
    padding-left: 5px;
}

.theirPrompt>p {
    font-size: 10px;
    letter-spacing: -0.2px;
    word-spacing: -1.4px;
    font-weight: normal;
    margin-bottom: 1px;
}

.yourPrompt>p {
    font-size: 10px;
    letter-spacing: -0.2px;
    word-spacing: -1.4px;
    font-weight: bold;
    margin-bottom: 1px;
    color: var(--green-primary);
}

.votePrompt {
    color: var(--blue-primary);
}

.createPrompt {
    color: var(--blue-primary);
}

.characterClass {
    color: var(--blue-primary);
}

.actionClass {
    color: var(--green-primary);
}

.modifierClass {
    color: var(--purple-primary);
}


/* Mat */

.error-snackbar {
    background: var(--red-primary);
    color: var(--white-primary);
}

.warning-snackbar {
    background: var(--yellow-primary);
    color: var(--black-primary);
}

.success-snackbar {
    background: var(--green-primary);
    color: var(--white-primary);
}

.mat-mdc-progress-spinner circle,
.mat-mdc-spinner circle {
    stroke: var(--text-color);
}

.mat-mdc-tab-label {
    min-width: 0!important;
}

.mat-mdc-tooltip {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
    background: rgba(40, 40, 40, .9);
}

.mat-mdc-radio-button .mat-internal-form-field,
.mat-bottom-sheet-container,
.mat-mdc-dialog-container,
.mat-mdc-dialog-surface,
.mat-mdc-tab,
.mat-mdc-tab-labels,
.mdc-tab__text-label,
.mat-mdc-dialog-container .mat-mdc-dialog-content {
    background: var(--card-background) !important;
    color: var(--text-color) !important;
}


/* Remove */

.slider.slider-horizontal {
    width: 98%;
}

.sliderFrame {
    padding-left: 15px;
    padding-bottom: 30px;
    padding-top: 10px;
}

.sliderHolder {
    width: 98%
}

.leftImg {
    opacity: 0.4;
    max-width: 24%;
    height: auto;
    max-width: calc(24% * (var(--n) / 100));
}

.rightImg {
    opacity: 0.4;
    max-width: 24%;
    height: auto;
    max-width: calc(24% * (var(--n) / 100));
}

.mainImg {
    border: 2px solid var(--blue-primary);
    max-width: 50%;
    background-color: var(--card-background);
    height: auto;
    max-width: calc(50% * (var(--n) / 100));
}

.color-box {
    width: 40px;
    height: 40px;
    display: inline-block;
    background-color: #ccc;
    border: 2px solid var(--card-background);
    position: relative;
    margin-left: 20px;
}

.sb-drawingIcon {
    height: 100px;
    width: auto;
    margin: 5px;
    background-color: white;
}

.RadioAnswer img {
    float: left;
    height: 100px;
    width: auto;
    margin: 5px;
    background-color: white;
}

.sb-colorpicker-dialog {
    flex-wrap: wrap;
    width: 500px;
    height: 400px;
}

.scroll-down {
    position: absolute;
    left: 50%;
    bottom: 10px;
    display: block;
    cursor: pointer;
    text-align: center;
    font-size: 40px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0;
    width: 26px;
    height: 26px;
    border-bottom: 2px solid var(--green-primary);
    border-right: 2px solid var(--green-primary);
    z-index: 9;
    left: 50%;
    -webkit-transform: translate(-50%, 0%) rotate(45deg);
    -moz-transform: translate(-50%, 0%) rotate(45deg);
    transform: translate(-50%, 0%) rotate(45deg);
    -webkit-animation: fade_move_down 4s ease-in-out infinite;
    -moz-animation: fade_move_down 4s ease-in-out infinite;
    animation: fade_move_down 4s ease-in-out infinite;
}


/*animated scroll arrow animation*/

@-webkit-keyframes fade_move_down {
    0% {
        -webkit-transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@-moz-keyframes fade_move_down {
    0% {
        -moz-transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -moz-transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@keyframes fade_move_down {
    0% {
        transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@media only screen and (max-width: 768px) {
    #mobile-play {
        display: block;
    }
}

@media only screen and (max-width: 420px) {
    .catchphrase {
        flex-direction: column;
        align-items: center;
    }
}